import React, { memo, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { get, flatten } from 'lodash';
import { useSnackbar } from 'notistack';

import { PASSING_PERCENT } from '@nereus/shared/constants';
import { EXAM_LIST_PAGE as QUERY, GET_COMP_SCORE } from 'constants/queries';
import NoPrint from 'components/common/NoPrint';
import B from 'components/common/B';
import Loading from 'components/common/Loading';
import Exam from './Exam';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';
import ExitExamModeButton from './ExitExamModeButton';
import ExamPrintReportButton from './ExamPrintReportButton';
import PercentDisplay from './PercentDisplay';
import PrintIcon from '@material-ui/icons/Print';
import {
  NAME_XS,
  EXAM_XS,
  SCORE_XS,
  PERCENT_XS,
  ATTEMPTS_XS,
  BUTTON_XS,
} from './constants';
import { useQuery } from 'utils';
import { getSavedExamAnswers, getCurrentUserID } from 'reduxConfig/selectors';
import { useSelector } from 'react-redux';

const ExamList = () => {
  const { courseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [itemScore, setItemScore] = useState([]);
  const uid = useSelector(getCurrentUserID);
  const userid = parseInt(uid);
  const cid = parseInt(courseId);
  const [rowData, setrowData] = useState([]);

  const { data, LoadingProps } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching your exams',
    variables: { courseId },
  });

  useEffect(() => {
    console.log('NEW: '+JSON.stringify(rowData));
  });

  const { loading: gettingScores, refetch: refetchScores } = useQuery(
    GET_COMP_SCORE,
    {
      // variables: {userid},
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        {
          setrowData(data["getcompetencescore"]);
        }
      },
      onError: (error) => {
        console.log(error.message);
      },
    }
  );
  
  const categories = get(data, 'courseCategoriesWithExamFlag.courseCategories', []);
  const maxExamAttempts = get(data, 'courseCategoriesWithExamFlag.maxExamAttempts', null);

  const flattenedExams = flatten(categories.map(category => category.exams));
  const allExamsAttempted = flattenedExams.every(x => x.attempts > 0);
  const mostAttemptedExamCount = flattenedExams.reduce((a, { attempts:b }) => Math.max(a, b), 0);
  const allExamsHaveSameAttempts = flattenedExams.every(exam => exam.attempts === mostAttemptedExamCount);
  const totalScore = flattenedExams.reduce((sum, x) => sum + x.score, 0);
  const totalItems = flattenedExams.reduce((sum, x) => sum + x.numberOfQuestions, 0);
  const percent = (totalScore / totalItems) * 100;
  const canExitExam = allExamsAttempted && allExamsHaveSameAttempts;

  const openReports = () => {
    window.open('/exam-results', '_blank', 
      Object.entries({
        toolbar: 0,
        location: 0,
        menubar: 0,
        height: Math.min(Math.max(window.screen.height*.5, 1280), window.screen.height*.75),
        width: Math.min(Math.max(window.screen.width*.5, 720), window.screen.width*.75),
      }).map(([k,v]) => `${k}=${v}`).join(',')
    )
  }

  console.log('CAT: '+JSON.stringify(categories));

  return (
    <Loading {...LoadingProps}>
      <Grid container direction='column' spacing={10}>
        <Grid container item xs={12} justify='space-between' spacing={10}>
          <Grid container item xs={12} spacing={4}>
            <Grid className="py-3 px-4" alignItems='center' container>
              <Grid container item xs={NAME_XS} className='pr-2'><B>Category</B></Grid>
              <Grid container item xs={EXAM_XS}>
                <Grid item xs={SCORE_XS}><B>Scores</B></Grid>
                <Grid item xs={PERCENT_XS}><B>Rating</B></Grid>
                <Grid item xs={ATTEMPTS_XS}><B>Attempts</B></Grid>
                <NoPrint>
                  <Grid item xs={BUTTON_XS} className='px-4'><B>Exams</B></Grid>
                </NoPrint>
              </Grid>
            </Grid>
          </Grid>

          {categories.map(category => {
            return (
              <Grid
                key={category.id}
                container
                item
                spacing={4}
                justify='space-between'
                alignItems='center'
                className='py-3 mb-2'
              >
                <Grid className="bg-white py-3 px-4 rounded" alignItems='center' container>
                  <Grid container item xs={NAME_XS}>
                    {category.name}{" "}
                    {category.items.map(item => {
                      return (
                        <p container item className='text-sm ml-5 mt-4'>
                          {item.name}
                        </p>
                      )
                    })}
                  </Grid>

                  <Grid container item xs={EXAM_XS}>
                    <Exam
                      exams={category.exams}
                      examItemScores={1}
                      maxExamAttempts={maxExamAttempts}
                      mostAttemptedExamCount={mostAttemptedExamCount}
                      allExamsHaveSameAttempts={allExamsHaveSameAttempts}
                    />
                    {/* {category.items.map(x => {
                      return (
                        <Grid container item className='text-sm mt-4'>
                          {x.id}
                          {rowData.map(item => {
                            return (
                              <Grid container item className='text-sm mt-4'>
                                {category.id == item.examId && x.id == item.courseItemID ? item.score : ''}
                              </Grid>
                            )
                          })}
                        </Grid>
                      )
                    })} */}
                    {/* {rowData.map(item => {
                      return (
                        category.id == item.examId ? item.score : ''
                      )
                    })} */}
                  </Grid>
                </Grid>
              </Grid>
            )
          })}

          {
            allExamsAttempted && (<>
              <Grid
                container
                item
                spacing={4}
                justify='space-between'
                alignItems='center'
                className='py-3 mb-2 pt-8'
              >
                <Grid className="bg-white rounded py-3 px-4" alignItems='center' container>
                  <Grid container item xs={NAME_XS}><B>AVERAGE RATING</B></Grid>
                  <Grid container item xs={EXAM_XS}>
                    <Grid item xs={SCORE_XS} />
                    <Grid item xs={PERCENT_XS}>
                      <PercentDisplay percent={percent} passingPercent={PASSING_PERCENT.course} />
                    </Grid>
                    <Grid item xs={ATTEMPTS_XS + BUTTON_XS} />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container item spacing={10} xs={12}>
                <Grid container item xs={12} spacing={4}>
                  <NoPrint>
                    <ExitExamModeButton disabled={!canExitExam} />
                  </NoPrint>
                  <Button
                    variant='contained'
                    aria-label='print'
                    className={'ml-2 bg-blue-400 text-white'}
                    onClick={openReports}
                  >
                    <PrintIcon />
                    Print
                  </Button>
                </Grid>
              </Grid>
            </>)
          }
        </Grid>
      </Grid>
    </Loading>
  )
}

export default memo(ExamList);
