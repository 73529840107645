import React, { memo, useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, FormControl } from '@material-ui/core';
import { KeyboardDatePicker, TimePicker } from '@material-ui/pickers'
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from "moment";
import ExamSchedules from './ExamSchedulesPage'
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";

import { login as LOGIN_VALIDATION } from '@nereus/shared/validation';
import { LOGIN as LOGIN_MUTATION } from 'constants/mutations';
import { login as loginAction } from 'reduxConfig/actions/currentUser';
import { useMutation } from 'utils';
// import Field from './_Field';
// import Form from './_Form';
import { EDIT_SCHEDULE } from 'constants/mutations';

const useStyles = makeStyles((theme) => ({
  image: {
    backgroundImage: "url('/images/main_background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    paddingLeft: 40,
    paddingRight: 40,
    minHeight: '100vh',
  },
}));

const EditExamScheduleModal = ({
  closeDialog,
  toggleShowModal,
  idExam,
  dateExam,
  timeExam,
  totalExam,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    console.log('Edit Exam'+idExam);
  });

  const [selectedDate, setDate] = useState(moment());
  const [inputValue, setInputValue] = useState(dateExam ? dateExam : moment().format("MM-DD-YYYY"));

  const onDateChange = (date, value) => {
    setDate(date);
    setInputValue(value);
  };
  const [selectedTime, setTime] = useState(moment());
  const [inputValueTime, setInputValueTime] = useState(timeExam ? timeExam : moment().format("h:m a"));

  const onTimeChange = (time, value) => {
    setTime(time);
    setInputValueTime(time);
    console.log('time:'+time+' val: '+value);
  };
  const [selectedPart, setPart] = useState(moment());
  const [ inputParticipants, setInputParticipants ] = useState(totalExam);
  const onPartChange = (part, value) => {
    setPart(part);
    setInputParticipants(value);
  };

  const handleClickConfirm = (evt) => {

    evt.preventDefault();
    mutation({
      variables: {
        examid: idExam,
        setdate: inputValue,
        settime: timeExam ? timeExam : inputValueTime.format("h:m a"),
        participants: parseInt(inputParticipants),
      },
    });
  }

  const [mutation, { loading: isLoading }, mutationResults] = useMutation(
    EDIT_SCHEDULE,
    {
      onCompleted: (data) => {
        console.log(data);
        window.location='/admin/exam-schedules';
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  return (
    <Modal
      open={true}
      center={true}
      showCloseIcon={false}
      onClose={() => toggleShowModal()}
    >
    <Grid>
      <FormControl
        // submitText='Create Schedule'
      >
        <Grid container item spacing={2}>
          <Grid container item>
            <KeyboardDatePicker
              variant='outlined'
              fullWidth
              format="MM-DD-YYYY"
              name='date'
              label='Date'
              value={selectedDate}
              onChange={onDateChange}
              inputValue={inputValue}
            />
          </Grid>

          <Grid container item>
            <TimePicker
              label='Time'
              name='time'
              fullWidth
              format="h:mm a"
              onChange={onTimeChange}
              value={selectedTime}
            />
          </Grid>

          <Grid container item>
            <TextField
              label='No. of Participants'
              name='participants'
              fullWidth
              type="number"
              InputProps={{ inputProps: { min: 0 } }}
              onInput={event => setInputParticipants(event.target.value)}
            />
          </Grid>
        </Grid>
      </FormControl>
      <Grid className='mt-5'>
        <Button
          variant='contained'
          className='bg-green-400 text-white'
          onClick={handleClickConfirm}
        >
          Confirm
        </Button>

        <Button
          variant='contained'
          onClick={closeDialog}
          autoFocus
        >
          Cancel
        </Button>
      </Grid>
    </Grid>
    </Modal>
  )
}

export default memo(EditExamScheduleModal);
