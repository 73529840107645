import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Courses from 'components/common/Courses';
import { COURSES_WITH_USER_COURSE } from 'constants/queries';
import { checkIfAdmin, checkIfCanTakeExams } from 'reduxConfig/selectors';
import Header from './Header';
import FileUploaderDialog from './FileUploaderDialog';

const CoursesPage = () => {
  const [courseUploadingFor, setCourseUploadingFor] = useState(null);
  const isAdmin = useSelector(checkIfAdmin);
  const canTakeExams = useSelector(checkIfCanTakeExams);

  if (!canTakeExams) {
    if (isAdmin) {
      return <Redirect to={'/admin/dashboard'} />;
    }

    // Should never happen:
    return <Redirect to={'/logout'} />;
  }
  
  return (<>
    <FileUploaderDialog
      course={courseUploadingFor}
      setCourseUploadingFor={setCourseUploadingFor}
    />

    <Courses
      query={COURSES_WITH_USER_COURSE}
      Header={Header}
      setCourseUploadingFor={setCourseUploadingFor}
      startExpanded={true}
      disableExpandCollapse={true}
      hideTakeExamButtons={true}
      inReviewMode={false} //Hide review button
      showCategoryItems={true}
    />
  </>)
}

export default memo(CoursesPage);
