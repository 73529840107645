import React, { memo } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FacebookIcon from '@material-ui/icons/Facebook';
import ServicesOffered from './ServicesOffered';

const useStyles = makeStyles((theme) => ({
  background: {
    backgroundImage: "url('/images/main_background.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5rem',
    height: 758,
  },
  aboutUsBackgroundRight: {
    backgroundImage: "url('/images/nereus_translucent.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 400
  },
  aboutUsBackgroundLeft: {
    backgroundImage: "url('/images/feature_1.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover'
  },
  contactUsBackground: {
    backgroundImage: "url('/images/contact_us.png')",
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logo3: {
    maxHeight: '15%',
    overflow: 'hidden',
  }
}));

const HomePage = () => {
  const classes = useStyles();

  return (
    <Grid container item xs={10}>
      <div className={classes.background}>
        <img className={``} src="/images/header-logo.png" alt='header-logo'/>
        <p className='text-white mt-10 text-lg'>powered by</p>
        <img className={classes.logo3} src='/images/old-header-logo.png' alt='header-logo'/>
        {/* <span className="text-5xl mt-16 text-white enrollNow" aria-label="ENROLL NOW AND AVAIL OUR" />
        <h2 className="text-4xl mt-12 text-center introductoryPrice"><span>PROMO</span><span className="ml-4">PRICE</span></h2>
        <h2 className="text-6xl mt-0 text-center introductoryPrice"><span>PHP</span><span>7,500</span></h2> */}
      </div>
      <Grid container item xs={5} classes={{ root: classes.aboutUsBackgroundLeft }}></Grid>
      <Grid id="about" container item xs={7} classes={{ root: [classes.aboutUsBackgroundRight, ' px-8 py-16 leading-7 bg-right-bottom']}}>
        <h2 className="text-xl font-medium mb-4">About Nereus Maritime Review Center</h2> 

        <p className="mb-3 text-sm">On September 28, 2020, the Department of Trade and Industry has issued the
          certificate of business name registration to <b>Nereus Maritime Review Center (NMRC)</b>.
          Primarily, Nereus Maritime Review Center is conceptualized to provide an online review
          services to aspiring Merchant Marine Officers for OIC and Management Levels both of
          the Marine Deck and Engine Departments. This is to address the current dilemma
          seafarers are experiencing in the midst of the Covid-19 pandemic, and where physical
          attendance of review classes is prohibited and likewise deemed impractical.
        </p>
        <p className="mb-3 text-sm">The officers and gentlemen behind the creation of NMRC are not just passionate about
          having a maritime review center but they are more committed towards provision of
          quality review materials for the merchant marine profession. In fact, NMRC has
          emerged to help address the dwindling number of the Maritime Industry Authority
          (MARINA) Board passers.
        </p>
        <p className="mb-3 text-sm">Moreover, since the brains of this company are former successful Merchant Marine
          Officers themselves, they have considered the idea of providing an affordable yet less
          complicated quality review to all wannabe OIC and Management Level Merchant Marine
          Officers. Likewise, with the current situation of the seafaring industry, where review
          courses have limitations, NMRC is offering this online review platform, so wishful
          seafarers could have their review anywhere around the globe.
        </p>
        <p className="mb-3 text-sm">As to the name, it was named after the Greek God <b><i>NEREUS</i></b> who was the old man of
          the sea, and the god of the sea’s rich bounty of fish. He dwelt in the depths of the
          Aegean with his wife Doris and fifty Nereid daughters. Like many of the other sea-gods <b>Nereus</b> was a master shapeshifter, and spoke with prophetic voice.
        </p>
        <p className="mb-3 text-sm">Hence, <b>the Nereus Maritime Review Center</b> is now here to serve the best interest of
          all qualified Filipino seafarers and be of help in the attainment of their career and life
          goals.
        </p>
      </Grid>     

      <Grid id="services" container spacing={1} className="px-12 pt-16 bg-gray-300 ">
        <Grid container item xs={12}>
          <h2 className="text-xl font-medium mb-4 ml-4">Mission</h2>
          <p className="ml-4 mb-5">NEREUS is dedicated to serve as an avenue for the pursuit of competence by providing quality review materials that will challenge the ability of merchant marine officers in wanting to elevate their careers.</p>
          <h2 className="text-xl font-medium mb-4 ml-4">Vision</h2>
          <p className="ml-4">NEREUS shall evolve as a dynamic maritime review center that satisfies the needs of aspiring merchant marine officers in achieving the level of competence inherent to their responsibilities on board.</p>
        </Grid>
      </Grid>

      <Grid id="services-offered" container spacing={1} className="px-12 py-16 bg-gray-300 ">
        <ServicesOffered />
      </Grid>

      <Grid container spacing={1} className="">
        <Grid item xs={5} className="bg-white">
          <Grid container spacing={2} className="px-16 pt-16 pb-24">
            <Grid item xs={12}>
              <h2 className="text-2xl text-center font-medium mb-6">Contact Us</h2>
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" label="Name" variant="outlined" className="w-full mb-4"/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" label="Email" variant="outlined" className="w-full mb-4"/>
            </Grid>
            <Grid item xs={12}>
              <TextField id="outlined-basic" label="Phone" variant="outlined" className="w-full mb-4"/>
            </Grid>
            <Grid item xs={12}>
              <div className="h-24">
              <TextField
                id="outlined-textarea"
                className="w-full"
                label="Message"
                multiline
                rows={5}
                variant="outlined"
              />
              </div>
            </Grid>
          </Grid>
        </Grid> 
        <Grid item xs={7} className={classes.contactUsBackground}>
        </Grid> 
      </Grid>  

      {/* TODO Make a footer component */}
      <Grid container item direction="row" justify="flex-start" alignItems="flex-start" className="bg-black text-white px-20 pb-24 pt-12">
        <Grid item xs={3}>
          <p className="font-medium mb-3">Office Address</p>
          <p>21B Greenheights Avenue</p>
          <p>San Isidro, Parañaque City</p>
        </Grid>
        <Grid item xs={4}>
          <p className="font-medium mb-3">Contact Details</p>
          <p>Contact Number: 09774008738</p>
          <p>E-mail: nereusreview2020@gmail.com</p>
        </Grid>

        <Grid item xs={3}>
          <p className="font-medium mb-3">Social Media</p>
          <p><a href="https://www.facebook.com/Nereus-Maritime-Review-115320407265437" target="_blank" rel="noopener noreferrer"><FacebookIcon/></a></p>
        </Grid>

        <Grid container item direction="row" justify="space-between" xs={12} className="pt-12">
          <p>© 2020 Nereus Maritime Review Center. All Rights Reserved.</p>

          <div>
            <a href="/" className="mr-12">Privacy Policy</a>
            <a href="/">Terms and Conditions</a>
          </div>

        </Grid>
      </Grid>
    </Grid>
  )
}

export default memo(HomePage);
