// Basically this used to handle everything exam related, but the client
// wanted a new feature where pages would be separated by their "course item" (aka competence).
//
// Because of this, renamed this to "ExamContent", and made another component called "Exam"
// that handles a lot of the logic and passes needed props here:
import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Typography } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { noop } from 'lodash';
import useKeypress from 'react-use-keypress';
import { scroller } from 'react-scroll';

import Loading from 'components/common/Loading';
import { useGlobalDialog } from 'globalDialog';
import Questions from './Questions';
import ConfirmActions from './ConfirmActions';
import Results from './Results';

const scrollToTop = () => {
  scroller.scrollTo('top-of-exam', {
    delay: 0,
    smooth: 'easeInOutQuart'
  });
}

const ExamContent = ({
  currentPage,
  lastPage,
  setCurrentPage: setCurrentPageNoVerify,
  setAnswer,
  submit,
  submity,
  submitting,
  totalQuestionsCount,
  correctAnswerCount,
  examName,
  subtitle,
  initialized,
  ResultsFooter,
  ...rest
}) => {
  const {
    open: openDialog,
    close: closeDialog,
  } = useGlobalDialog();
  // if(correctAnswerCount !== null && false){
  //   correctAnswerCount = null;
  // }
  // const finishedTaking = (correctAnswerCount !== null) && false
  const finishedTaking = correctAnswerCount !== null;
  
  const setCurrentPage = page => {
    scrollToTop();
    setCurrentPageNoVerify(Math.min(Math.max(1, page), lastPage));
  }

  useKeypress(['ArrowLeft', 'ArrowRight'], (event) => {
    if (event.key === 'ArrowLeft') {
      setCurrentPage(currentPage - 1);
    } else if (event.key === 'ArrowRight') {
      setCurrentPage(currentPage + 1);
      // submity();
      console.log('correctAnswerCount: '+correctAnswerCount)
    }
  });

  // const confirmSubmity = () => {
  //   openDialog({
  //     message: 'Next?',
  //     actions: <ConfirmActions closeDialog={closeDialog} submit={submity} />
  //   });
  // }

  const confirmSubmit = () => {
    openDialog({
      message: 'Submit your answers now?',
      actions: <ConfirmActions closeDialog={closeDialog} submit={submit} />
    });
  }

  const handleChangePage = (_, value) => {setCurrentPage(value);}
  const handleChangeAnswer = (key, event) => setAnswer(key, event.target.value);

  return (
    <Loading loading={!initialized}>
      <Grid container spacing={4} className='bg-white rounded py-12 px-5'>
        <Grid item container spacing={4} direction='column'>
          <Grid container item justify='center' spacing={2}>
            <Grid item xs={12} id='top-of-exam'>
              <Typography variant='h5' className='text-center font-bold'>
                {examName}
              </Typography>
            </Grid>
            
            {/* { subtitle && (
              <Grid item xs={12}>
                <Typography variant='h6' mt={6} className='text-center font-bold'>
                  {subtitle}
                </Typography>
              </Grid>
            )} */}
          </Grid>

          {
            !submitting && (
              !finishedTaking ? (
                <Questions
                  subtitle={subtitle}
                  {...rest}
                  handleChangeRadio={handleChangeAnswer}
                />
              ) : (
                <Results
                  subtitle={subtitle}
                  questionsCount={totalQuestionsCount}
                  correctAnswerCount={correctAnswerCount}
                  Footer={ResultsFooter}
                />
              )
            )
          }
        </Grid>

        {
          !submitting && !finishedTaking && (
            <Grid item container justify='center' spacing={3} direction='column'>
              <Grid item container justify='center' className={`exam-pagination`}>
                <Pagination
                  page={currentPage}
                  count={lastPage}
                  onChange={handleChangePage}
                  color='primary'
                />
              </Grid>

              {currentPage == lastPage && (
                <Grid item container justify='center'>
                  <Button variant='contained' color='primary' onClick={confirmSubmit}>
                    Submit
                  </Button>
                </Grid>
              )}
            </Grid>
          )
        }
      </Grid>
    </Loading>
  )
}

ExamContent.propTypes = {
  currentPage: PropTypes.number.isRequired,
  lastPage: PropTypes.number.isRequired,
  setCurrentPage: PropTypes.func.isRequired,
  setAnswer: PropTypes.func.isRequired,
  submit: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  totalQuestionsCount: PropTypes.number,
  correctAnswerCount: PropTypes.number,
  examName: PropTypes.string,
  subtitle: PropTypes.string,
  initialized: PropTypes.bool,
  Footer: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.object,
  ]),
}

ExamContent.defaultProps = {
  currentPage: 1,
  lastPage: 1,
  setCurrentPage: noop,
  setAnswer: noop,
  submit: noop,
  submitting: false,
  totalQuestionsCount: 0,
  correctAnswerCount: 0,
  examName: 'Exam',
  initialized: true,
}

export default memo(ExamContent);
