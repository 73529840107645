import React, { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import {
  List,
  ListItem as ListItemBase,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import ExamSchedulesIcon from '@material-ui/icons/Event';
import AdminDashboardIcon from '@material-ui/icons/Star';
import ReportIcon from '@material-ui/icons/Assessment';
import CoursesIcon from '@material-ui/icons/Dashboard';
import CoursesExamIcon from '@material-ui/icons/Description';
import ProfileEditIcon from '@material-ui/icons/Edit';
import ApproveDenyIcon from '@material-ui/icons/ThumbsUpDown';
import UsersIcon from '@material-ui/icons/People';
import LogoutIcon from '@material-ui/icons/ExitToApp';

import ExamMode from 'components/common/ExamMode';
import BackgroundImage from 'components/common/BackgroundImageWithImage';
import { checkIfAdmin, checkIfShowCoursesInExamMode } from 'reduxConfig/selectors';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '100%',
  },
  listRoot: {
    flex: 1,
    backgroundColor: theme.palette.background.paper,
  },
}));

const ListItem = ({ text, url, Icon, conditions = true, ...rest }) => {
  const history = useHistory();

  if (!conditions) return null;

  return (
    <ListItemBase button onClick={() => history.push(url)} {...rest}>
      {
        Icon && (
          <ListItemIcon className='pl-4'>
            <Icon />
          </ListItemIcon>
        )
      }

      <ListItemText primary={text} />
    </ListItemBase>
  )
}

const Sidebar = () => {
  const classes = useStyles();
  const isAdmin = useSelector(checkIfAdmin);
  const showCoursesInExamMode = useSelector(checkIfShowCoursesInExamMode);

  const openReports = () => {
    window.open('/admin/report', '_blank', 
      Object.entries({
        toolbar: 0,
        location: 0,
        menubar: 0,
        height: Math.min(Math.max(window.screen.height*.5, 1280), window.screen.height*.75),
        width: Math.min(Math.max(window.screen.width*.5, 720), window.screen.width*.75),
      }).map(([k,v]) => `${k}=${v}`).join(',')
    )
  }

  return (
    <div className={classes.container}>
      <BackgroundImage style={{ height: 300 }} />

      <List
        component='nav'
        aria-label='main mailbox folders'
        classes={{ root: classes.listRoot }}
      >
        <ExamMode.Hide dontHideIfTrue={showCoursesInExamMode}>
          <ListItem
            text='All Courses'
            Icon={CoursesIcon}
            url='/courses'
            conditions={isAdmin}
          />
        </ExamMode.Hide>

        <ExamMode.Hide>
          <ListItem
            text='Exams'
            Icon={CoursesExamIcon}
            url='/course-exam-list'
          />
        </ExamMode.Hide>

        <ExamMode.Show>
          <ListItem
            text='Exams'
            Icon={CoursesIcon}
            url={`/exam-list`}
          />
        </ExamMode.Show>

        <ListItem
          text='Exam Schedules'
          Icon={ExamSchedulesIcon}
          url='/admin/exam-schedules'
          conditions={isAdmin}
        />

        <ListItem
          text='Admin Dashboard'
          Icon={AdminDashboardIcon}
          url='/admin/dashboard'
          conditions={isAdmin}
        />

        <ListItem
          text='Generate Report'
          Icon={ReportIcon}
          url='/admin/report'
          conditions={isAdmin}
          onClick={openReports}
        />

        <ListItem
          text='Approve/Deny Payments'
          Icon={ApproveDenyIcon}
          url='/admin/approve-deny-payments'
          conditions={isAdmin}
        />

        <ListItem
          text='Users'
          Icon={UsersIcon}
          url='/admin/users'
          conditions={isAdmin}
        />

        <ListItem
          text='Edit Profile'
          Icon={ProfileEditIcon}
          url={`/profile-edit`}
        />

        <ExamMode.Hide>
          <ListItem
            text='Logout'
            Icon={LogoutIcon}
            url='/logout'
          />
        </ExamMode.Hide>
      </List>
    </div>
  )
}

export default memo(Sidebar);
