import { gql } from '@apollo/client';

const _USER_FRAGMENT = gql`
  fragment UserFields on User {
    id
    username
    email
    isAdmin
    canTakeExams
    canAlwaysAccessCourse
    showCoursesInExamMode
    takingExamForCourseId
    examCheatsEnabled
    firstName
    middleName
    lastName
    graduatedFrom
    graduatedOn
    birthday
    address
    phoneNumber
    company
    referredBy
    rank {
      id
      name
    }
  }
`

const LOGIN = gql`
  ${_USER_FRAGMENT}

  mutation LoginMutation(
    $username: String!
    $password: String!
  ) {
    login(
      username: $username,
      password: $password
    ) {
      token
      user {
        ...UserFields
      }
    }
  }
`

const REGISTER = gql`
  ${_USER_FRAGMENT}

  mutation RegisterMutation(
    $username: String!
    $email: String!
    $password: String!
    $password2: String!
    $firstName: String!
    $middleName: String!
    $lastName: String!
    $rank: Int!
    $graduatedFrom: String!
    $graduatedOn: String!
    $birthday: String!
    $address: String!
    $phoneNumber: String!
    $company: String
    $referredBy: String
  ) {
    signup(
      username: $username,
      email: $email,
      password: $password,
      password2: $password2,
      firstName: $firstName,
      middleName: $middleName,
      lastName: $lastName,
      rank: $rank
      graduatedFrom: $graduatedFrom,
      graduatedOn: $graduatedOn,
      birthday: $birthday,
      address: $address,
      phoneNumber: $phoneNumber,
      company: $company,
      referredBy: $referredBy,
    ) {
      token
      user {
        ...UserFields
      }
    }
  }
`

const UPDATE_PROFILE = gql`
  ${_USER_FRAGMENT}

  mutation updateProfile(
    $currentPassword: String!
    $email: String
    $newPassword: String
    $firstName: String
    $middleName: String
    $lastName: String
    $rank: Int
    $graduatedFrom: String
    $graduatedOn: String
    $birthday: String
    $address: String
    $phoneNumber: String
    $company: String
    $referredBy: String
  ) {
    updateProfile(
      currentPassword: $currentPassword,
      email: $email,
      newPassword: $newPassword,
      firstName: $firstName,
      middleName: $middleName,
      lastName: $lastName,
      rank: $rank
      graduatedFrom: $graduatedFrom,
      graduatedOn: $graduatedOn,
      birthday: $birthday,
      address: $address,
      phoneNumber: $phoneNumber,
      company: $company,
      referredBy: $referredBy,
    ) {
      token
      user {
        ...UserFields
      }
    }
  }
`

const APPLY_COURSE = gql`
  mutation applyCourse($courseId: Int!) {
    applyCourse(courseId: $courseId) {
      id
      code
      name
      userCourse {
        status
        approverDenierMessage
      }
    }
  }
`

const PAY_COURSE = gql`
  mutation payCourse(
    $courseId: Int!,
    $receiptImage: Upload!,
    $receiptComment: String,
    $receiptAmountpaid: String
  ) {
    payCourse(
      courseId: $courseId,
      receiptImage: $receiptImage,
      receiptComment: $receiptComment,
      receiptAmountpaid: $receiptAmountpaid
    ) {
      id
      status
      course {
        id
      }
      receiptPath
    }
  }
`

const APPROVE_DENY_COURSE_APPLICATION = gql`
  mutation approveDenyCourseApplication(
    $id: Int!,
    $approve: Boolean!,
    $approverDenierMessage: String
  ) {
    approveDenyCourseApplication(
      id: $id,
      approve: $approve,
      approverDenierMessage: $approverDenierMessage
    ) {
      id
      approverDenierMessage
    }
  }
`

const ADD_EXAM_SCHEDULE = gql`
  mutation toggleAddSched($approve: Boolean!) {
    toggleAddSched(approve: $approve)
  }
`

const TOGGLE_EXAM_MODE = gql`
  mutation toggleExamMode($courseId: Int) {
    toggleExamMode (courseId: $courseId)
  }
`

const BEGIN_EXAM = gql`
  mutation beginExam($examId: Int!) {
    beginExam (examId: $examId) {
      id
      exam {
        name
      }
      attemptNumber
      qas {
        id
        choice {
          id
        }
        question {
          id
          text
          points
          choices {
            id
            text
            isCorrect # null if you are not an admin
          }
          courseItem {
            id
            name
          }
        }
      }
    }
  }
`

const ANSWER_EXAM = gql`
  mutation answerExam($examId: Int!, $answers: [UserAnswerInput!]) {
    answerExam(examId: $examId, answers: $answers) {
      id
      attemptNumber
      score
    }
  }
`

const LOG_SITE_VISIT = gql`
  ${_USER_FRAGMENT}

  mutation logSiteVisit {
    logSiteVisit {
      count
      ip
      serverVersion
      serverEnv
      user {
        ...UserFields
      }
    }
  }
`

export const ADD_SCHEDULE = gql`
  mutation addschedule($setdate: String, $settime: String, $participants: Int) {
    addschedule(
      exameDate: $setdate, 
      examTime: $settime, 
      examTotal: $participants,
    ){
      id
      exameDate
      examTime
      examTotal
    }
  }
`;

export const EDIT_SCHEDULE = gql`
  mutation editschedule($examid: Int!, $setdate: String, $settime: String, $participants: Int) {
    editschedule(
      examid: $examid,
      exameDate: $setdate, 
      examTime: $settime, 
      examTotal: $participants,
    ){
      id
      exameDate
      examTime
      examTotal
    }
  }
`;

export const UPDATE_USERCOURSE_SCHED = gql `
  mutation updateschedule(
    $id: Int!, 
    $userId: Int, 
    $schedule: String
  ) {
    updateschedule(
      id: $id,
      userId: $userId,
      schedule: $schedule,
    ) {
      id
      userId
      schedule
    }
  }
`;

export const ADD_USER_SCHEDULE = gql `
  mutation adduserschedule($examschedId: Int!, $usercourseId: Int){
    adduserschedule(
      examschedId: $examschedId,
      usercourseId: $usercourseId,
    ){
      examschedId
      usercourseId
    }
  }
`;

export const DELETE_SCHEDULE = gql `
  mutation deleteschedule(
    $id: Int!,
  ) {
    deleteschedule(
      id: $id,
    ){
      id
    }
  }
`;

export const COMP_USER_SCORE = gql `
  mutation comp_user_score(
    $courseItemID: Int,
    $userid: Int,
    $score: Int,
    $examId: Int,
  ) {
    comp_user_score(
      courseItemID: $courseItemID,
      userid: $userid,
      score: $score,
      examId: $examId,
    ){
      id
    }
  }
`;

export {
  LOGIN,
  REGISTER,
  UPDATE_PROFILE,
  APPLY_COURSE,
  PAY_COURSE,
  ADD_EXAM_SCHEDULE,
  APPROVE_DENY_COURSE_APPLICATION,
  TOGGLE_EXAM_MODE,
  BEGIN_EXAM,
  ANSWER_EXAM,
  LOG_SITE_VISIT,
};
