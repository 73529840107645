import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, TextField, Grid } from '@material-ui/core';
import { get } from 'lodash';
import { useSnackbar } from 'notistack';
import { useGlobalDialog } from 'globalDialog';

import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import { GET_EXAM_SCHEDULE } from 'constants/queries';
import { getApiUrl, displayDate as d } from 'utils';
import AddSchedButton from './AddSchedButton';
import EditExamScheduleModal from './EditExamScheduleModal';
import AddSchedModal from './AddSchedModal';
import Table from 'components/common/Table';
import Loading from 'components/common/Loading';
import { useQuery, useMutation } from 'utils';
import B from 'components/common/B';
import { DELETE_SCHEDULE } from 'constants/mutations';

import {
  Actions as ConfirmTakeExamActions,
  Title as ConfirmTakeExamTitle,
} from 'components/common/Courses/Course/TakeExamsButton';

// Will be done just backend for now:
// const sortStatus = x => {
//   switch (x) {
//     case STATUS.pendingApproval: return 0;
//     case STATUS.rejected: return 1;
//     case STATUS.accepted: return 2;
//     default: return 3;
//   }
// }

const formatStatus = x => {
  switch (x) {
    case STATUS.pendingApproval: return 'Pending';
    case STATUS.rejected: return 'Rejected';
    case STATUS.accepted: return 'Accepted';
    default: return '?';
  }
}

const ExamSchedules = ({
  queryVariables,
  hideActions,
  hideStatus,
  hideAppliedOn,
  showApprovedDeniedOn,
  showApproverDenierMessage,
  showPaymentPostedOn,
  approvedDeniedOnLabel,
  approverDenierMessageLabel,
  disablePagination,
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [rowData, setrowData] = useState([]);
  const [ idExam, setIdExam] = useState();
  const [ dateExam, setDateExam] = useState();
  const [ timeExam, setTimeExam] = useState();
  const [ totalExam, setTotalExam] = useState();
  const [showModal, setShowModal] = useState(false);
  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const { data, LoadingProps } = useQuery(GET_EXAM_SCHEDULE, {
    variables: queryVariables,
    enqueueSnackbar,
    errorMessage: `Error loading course approvals`,
  });

  const {
    open: openDialog,
    close: closeDialog,
  } = useGlobalDialog();

  // const rows = get(data, 'getExamSchedule', []);

  useEffect(() => {
    if (data) {
      setrowData(data["getExamSchedule"]);
    }
    if(idExam){
      toggleModal();
    }
    console.log('ID: '+idExam);
  }, [data]);

  const [idToReject, setIdToReject] = useState(null);

  const openModal = id => setIdToReject(id);
  const closeModal = () => setIdToReject(null);

  const onDelete = (i) => {

    mutation({
      variables: {
        id: parseInt(i),
      },
    });
  }

  const [mutation, { loading: isLoading }, mutationResults] = useMutation(
    DELETE_SCHEDULE,
    {
      onCompleted: (data) => {
        console.log('DELETE SCHEDULE: '+data);
      },
      onError: (err) => {
        console.log(err);
      },
    }
  );

  const onClick = () => {
    openDialog({
      message: <AddSchedModal closeDialog={closeDialog}/>,
      title: "Add Exam Schedule",
      actions: false,
    });
  }


  return (
    <Grid className='w-full'>
      {showModal && (
        <EditExamScheduleModal
          toggleShowModal={() => toggleModal()}
          idExam={idExam}
          dateExam={dateExam}
          timeExam={timeExam}
          totalExam={totalExam}
        ></EditExamScheduleModal>
      )}
      <Grid container alignItems='center' justify='center' spacing={2}>
        <B variant='h4'>Exam Scheduler</B>
      </Grid>
    <Loading {...LoadingProps}>
      <Grid className='mt-5 float-right'>
      <Button
        className="mb-3"
        variant='contained'
        color='primary'
        onClick={onClick}
      >
        Add Schedule
      </Button>
      </Grid>

      <Table
        rows={rowData}
        columns={[
          { label: 'ID', get: row => row.id },
          { label: 'Date', get: row => row.exameDate },
          { label: 'Time', get: row => row.examTime },
          { label: 'No. of Examinees', get: row => row.examTotal },
          { label: 'Actions', render: row => {
            
            return (<>
              <Button
                className=""
                variant='contained'
                color='primary'
                onClick={()=>
                  setIdExam(row.id),
                  setDateExam(row.exameDate),
                  setTimeExam(row.examTime),
                  setTotalExam(row.examTotal),
                  toggleModal
                }
              >
                Edit
              </Button>
              {" "}
              <Button
                className=""
                variant='contained'
                color='primary'
                onClick={()=>
                  onDelete(row.id)
                }
              >
                Delete
              </Button>
            </>)
          }},
        ]}
        disablePagination={disablePagination}
        {...rest}
      />
    </Loading>
    </Grid>
  )
}

ExamSchedules.propTypes = {
  queryVariables: PropTypes.object,
  hideActions: PropTypes.bool,
  hideStatus: PropTypes.bool,
  hideAppliedOn: PropTypes.bool,
  showApprovedDeniedOn: PropTypes.bool,
  showApproverDenierMessage: PropTypes.bool,
  showPaymentPostedOn: PropTypes.bool,
  approvedDeniedOnLabel: PropTypes.string,
  approverDenierMessageLabel: PropTypes.string,
  disablePagination: PropTypes.bool,
}

ExamSchedules.defaultProps = {
  hideActions: false,
  hideStatus: false,
  hideAppliedOn: false,
  showApprovedDeniedOn: false,
  showApproverDenierMessage: false,
  showPaymentPostedOn: false,
  approvedDeniedOnLabel: 'Approved/Denied On',
  approverDenierMessageLabel: 'Approved/Denied Reason:',
  disablePagination: false,
}

export default memo(ExamSchedules);
