import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { noop } from 'lodash';
import Question from './Question';
import { Button, Grid, Typography } from '@material-ui/core';

const ExamQuestions = ({
  subtitle,
  questions,
  userQA,
  liveCheckingResults,
  firstQuestionNumber,
  ...rest
}) => {
  return (
  <Grid>
    <Grid item xs={12}>
      <Typography variant='h6' mt={6} className='text-center font-bold mb-5'>
        {subtitle}
      </Typography>
    </Grid>
  { questions.map(({ id, text, choices = []}, idx) => {
    const questionNumber = idx + firstQuestionNumber;
    const currentAnswer = userQA[id] || null;
    const isCorrectChoiceSelected = liveCheckingResults[id] === currentAnswer;

    return (
      <Question
          key={id}
          {...rest}
          id={id}
          text={text}
          choices={choices}
          questionNumber={questionNumber}
          currentAnswer={currentAnswer}
          isCorrectChoiceSelected={isCorrectChoiceSelected}
      />
    )
  })}
  </Grid>
  );
}

ExamQuestions.propTypes = {
  questions: PropTypes.array,
  userQA: PropTypes.object,
  liveCheckingResults: PropTypes.object,
  handleChangeRadio: PropTypes.func,
  firstQuestionNumber: PropTypes.number,
}

ExamQuestions.defaultProps = {
  questions: [],
  userQA: {},
  liveCheckingResults: {},
  handleChangeRadio: noop,
  firstQuestionNumber: 1,
}

export default memo(ExamQuestions);
