import React, { memo, useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { get, flatten } from 'lodash';
import { useSnackbar } from 'notistack';
import { useParams } from 'react-router-dom';
import { COURSE_STATUS as STATUS } from '@nereus/shared/constants';
import PrintButton from 'components/common/PrintButton';
import { PASSING_PERCENT } from '@nereus/shared/constants';

import { Typography, Grid } from '@material-ui/core';
import { getCurrentUser } from 'reduxConfig/selectors';
import { EXAM_LIST_PAGE as QUERY, GET_USER_SCHEDULE, GET_SCHEDULE } from 'constants/queries';
import { useQuery } from 'utils';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@material-ui/core';

import Users from 'components/pages/UsersPage';
import CustomHeader from '../ReportPage/CustomHeader';
import Item from '../ReportPage/Item';
import CourseApprovals from '../ReportPage/CourseApprovals';
import NoPrint from 'components/common/NoPrint';

const useStyles = makeStyles((theme) => ({
  container: {
    marginLeft: 32,
    marginRight: 32,
    
  },
}));

const ExamResultsReportPage = () => {
  const classes = useStyles();
  const user = useSelector(getCurrentUser);
  const { courseId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const [ schedUsers, setSchedUser ] = useState([]);
  // console.log(user.id);

  const { data } = useQuery(QUERY, {
    enqueueSnackbar,
    errorMessage: 'Error fetching your exams',
    variables: { courseId },
  });
  const categories = get(data, 'courseCategoriesWithExamFlag.courseCategories', []);
  const flattenedExams = flatten(categories.map(category => category.exams));
  const totalScore = flattenedExams.reduce((sum, x) => sum + x.score, 0);
  const totalItems = flattenedExams.reduce((sum, x) => sum + x.numberOfQuestions, 0);
  const percent = (totalScore / totalItems) * 100;


  const { loading: gettingSchedule, refetch: refetchSchedule } = useQuery(
    GET_USER_SCHEDULE,
    {
      variables: {userId: parseInt(user.id)},
      fetchPolicy: "cache-and-network",
      onCompleted: (data) => {
        {
          setSchedUser(data.getcurrentuserschedule[0].schedule);
          console.log(data.getcurrentuserschedule[0].schedule);
        }
      },
      onError: (error) => {
        console.log(error);
      },
    }
  );

  useEffect(() => {
    console.log('USE EFFECT!'+ schedUsers);
  });

  return (<>
    <div className={`${classes.container} bg-gray-200`}>
      <Item>
        <Grid container xs={12} >
          <Grid container justify='center' align='center'>
            <Grid item xs={4}>
              <img
                className={classes.image}
                src='/images/header-logo.png'
                alt='header-logo'
              />
            </Grid>

            <Grid item xs={4}>
              <Typography variant="h4" className="mt-6">
                Exam Results
              </Typography>
              <Typography variant="subtitle1" className="mt-1">
                OIC of an Engineering Watch
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <img
                className={classes.image}
                src='/images/protectmarine-logo.png'
                alt='protectmarine-logo'
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} className="mt-4">
          <Grid item xs={6}>
            <Grid>
              <Typography variant="subtitle1">Examinee: {user.firstName} {user.lastName}</Typography>
            </Grid>
          </Grid>

          <Grid item xs={6}>
            <Grid align='right'>
              <Typography variant="subtitle1">Examination Date: {schedUsers}</Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid container xs={12} className="mt-2">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Function</TableCell>
                  <TableCell>Competence</TableCell>
                  <TableCell>Score</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {categories.map(category => {
                  return (
                    <TableRow>
                      <TableCell>{category.name}</TableCell>
                      <TableCell></TableCell>
                      <TableCell>{category.exams[0].score}</TableCell>
                      <TableCell>{ (((category.exams[0].score/category.exams[0].numberOfQuestions) * 100) > PASSING_PERCENT.exam) ? "PASSED" : "FAILED"}</TableCell>
                    </TableRow>
                  )
                })}
                <TableRow>
                  <TableCell>OVERALL</TableCell>
                  <TableCell></TableCell>
                  <TableCell>{totalScore}</TableCell>
                  <TableCell>{percent > PASSING_PERCENT.course ? "PASSED" : "FAILED" }</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Item>
    </div>

    <NoPrint><PrintButton /></NoPrint>
  </>)
}

export default memo(ExamResultsReportPage);
