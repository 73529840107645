import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';

import { EXAM_SCHEDULES as QUERY } from 'constants/queries';
import { ADD_EXAM_SCHEDULE as MUTATION } from 'constants/mutations';
import { useMutation } from 'utils';

const AddSchedButton = ({
  ...rest
}) => {
  const { enqueueSnackbar } = useSnackbar();

  const [mutate] = useMutation(MUTATION, {
    enqueueSnackbar,
    successMessage: null,
    errorMessage: 'Error adding exam schedule',
  });

  const onClick = () => {
    mutate({ variables: { approve: true }});
  }

  return (
    <Button
      variant='contained'
      color='primary'
      onClick={onClick}
      {...rest}
    >
      Add Schedule
    </Button>
  )
}

AddSchedButton.propTypes = {
  
}

export default memo(AddSchedButton);
