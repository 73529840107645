import React from 'react';
import {
  useQuery as useQueryBase,
  useMutation as useMutationBase,
} from '@apollo/client';
import { has, get, isEmpty, isObject, isFunction, noop } from 'lodash';
import moment from 'moment';
import memoize from 'memoize-one';
import { DATE_DISPLAY } from 'constants/etc';

const getApiUrl = () => process.env.REACT_APP_API_URI || 'http://localhost:4001';

const checkInDevMode = (x = process.env.NODE_ENV) =>
  String(x || '').toLowerCase().startsWith('dev')

// At the moment, checkInDevMode() and checkInProdMode() are always opposite.
// This may change if, for example, we have "staging".
const checkInProdMode = (x = process.env.NODE_ENV) =>
  String(x || '').toLowerCase().startsWith('prod')

const displayDate = (dateToFormat, defaultRetVal = '') => {
  if (!dateToFormat) return defaultRetVal;
  // TODO Getting "value provided is not in a recognized RFC2822 or ISO format" warnings

  let x = moment(dateToFormat);
  x = x.isValid() ? x : moment(Number(dateToFormat));

  return x.isValid() ? x.format(DATE_DISPLAY) : defaultRetVal;
}

const displayMoney = (amount = 0, currency = '₱') => {
  try {
    const formattedAmount = Number(amount).toLocaleString(undefined, {
      useGrouping: true,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    return `${currency} ${formattedAmount}`;
  } catch(e) {
    console.warn("Error in 'displayMoney:'", e);
    return displayMoney(0, currency);
  }
}

const _optionalGet = (x, path = 'message') =>
  isObject(x) && path ? get(x, path) : String(x)

const _getFirstError = (errors = [], options = {}) => {
  const { path, withMore = true } = options;

  try {
    const [firstError, ...extraErrors] = errors;

    if (!withMore || extraErrors.length <= 0) {
      return _optionalGet(firstError, path);
    }

    return `${_optionalGet(firstError, path)} [+${extraErrors.length} more]`;

  } catch(e) {
    return 'Unknown error';
  }
}

const getResponseError = e => {
  const graphQLErrors = get(e, 'graphQLErrors');
  if (graphQLErrors && graphQLErrors.length > 0) {
    return _getFirstError(graphQLErrors);
  }

  if (has(e, 'networkError')) {
    const networkErrors = [].concat(get(e, 'networkError.result.errors'));

    if (networkErrors && networkErrors.length > 0) {
      return _getFirstError(networkErrors);
    }

    const statusText = get(e, 'networkError.response.statusText');
    if (statusText) return statusText;
  }

  return get(e, 'message') || String(e);
}

// Pls use useQuery and useMutation from here, and not @apollo/client.
// 
// This useQuery (based off https://gist.github.com/alexvilchis/64a5b07172e8e216c6542f4f4b637cdd)
// returns an 'inCache' bool, and a 'LoadingProps' object intended to be passed to <Loading />
// 
// It and useMutation can also generate onErrors and onCompleteds for us.
// Also rebind any accidental onSuccesses.
const _makeOnCompleted = ({
  enqueueSnackbar,
  successMessage,
  onCompleted = (data, generatedFunc) => generatedFunc(data),
}) => {
  let generatedFunc = noop;

  if (enqueueSnackbar && successMessage) {
    generatedFunc = () => enqueueSnackbar(successMessage, { variant: 'success' });
  }

  return data => onCompleted(data, generatedFunc);
}

const _makeOnError = ({
  enqueueSnackbar,
  errorMessage,
  onError = (e, generatedFunc) => generatedFunc(e),
  rethrowError,
}) => {
  let generatedFunc = console.error;

  if (enqueueSnackbar && errorMessage) {
    generatedFunc = e =>
      enqueueSnackbar(`${errorMessage}: ${getResponseError(e)}`, { variant: 'error' });
  }

  // We can't put in onCompleted or onError when we call mutate()
  // See https://spectrum.chat/apollo/react-apollo/oncompleted-not-firing-when-passed-as-an-option-in-usemutation~30d777c5-954e-4e96-b0ad-85f6660a4d98:
  // So if needed, we can just throw the error again if needed.
  // Note that refetches always seem to throw an error? Unclear if an Apollo bug or WAI:
  if (rethrowError) {
    return e => onError(e, e => {
      generatedFunc(e);
      throw e;
    })
  }

  return e => onError(e, generatedFunc);
}

const useQuery = (query, options = {}) => {
  let {
    autoSuccessErrorMessages = true,
    rethrowError = false,
    enqueueSnackbar,
    successMessage,
    errorMessage = 'Failed to fetch data',
    onCompleted,
    onSuccess,
    onError,
    ...rest
  } = options;

  if (onSuccess && checkInDevMode()) {
    console.warn('useQuery expects onCompleted, not onSuccess. Automatically fixing...');
    onCompleted = onSuccess;
  }

  const result = useQueryBase(query, {
    notifyOnNetworkStatusChange: true,
    ...rest,
    onCompleted,
    onError,
    ...(
      autoSuccessErrorMessages
        ? {
          onCompleted: _makeOnCompleted({ enqueueSnackbar, successMessage, onCompleted }),
          onError: _makeOnError({ enqueueSnackbar, errorMessage, onError, rethrowError }),
        } : {}
    ),
  });

  let inCache = true;
  if (result.loading) {
    try {
      inCache = Boolean(result.client.readQuery({
        query: query,
        variables: result.variables
      }));

    } catch (error) {
      inCache = false;
    }
  }

  return {
    ...result,
    inCache,
    LoadingProps: {
      inCache,
      loading: result.loading,
      error: result.error,
      refetch: result.refetch,
    }
  };
}

const useMutation = (query, options = {}) => {
  let {
    autoSuccessErrorMessages = true,
    rethrowError = false,
    enqueueSnackbar,
    successMessage = 'Data saved successfully',
    errorMessage = 'Error saving data',
    onCompleted,
    onSuccess,
    onError,
    ...rest
  } = options;

  if (onSuccess && checkInDevMode()) {
    console.warn('useMutation expects onCompleted, not onSuccess. Automatically fixing...');
    onCompleted = onSuccess;
  }

  return useMutationBase(query, {
    // Been manually writing update functions. Supposed to be automatic I think?
    // TODO Fix if this is the case
    // Or at the least semi-automatically generate them here.
    ...rest,
    ...(
      autoSuccessErrorMessages
        ? {
          onCompleted: _makeOnCompleted({ enqueueSnackbar, successMessage, onCompleted }),
          onError: _makeOnError({ enqueueSnackbar, errorMessage, onError, rethrowError }),
        } : {}
    ),
  });
}

// Still buggy. Use with care:
const renderComponentOrFunction = (X, props = {}) => {
  if (!X) return null;

  // TODO Fix this, does not always work as expected,
  // using isObject again for now:
  // if (React.isValidElement(X)) return <X {...props} />;
  if (isObject(X)) return <X {...props} />;

  if (isFunction(X)) return X(props);

  return X;
}

// TODO Maybe start splitting up utils.js:
const performChecking = memoize(({
  questions,
  userQA,
}) => { 
  const correctQA = _getAllCorrectQuestionIdsAndAnswerIds(questions);

  if (!correctQA || isEmpty(correctQA)) return {};

  return _getUsersCorrectQuestionIdsAndAnswerIds(userQA)(correctQA);
})

const _getAllCorrectQuestionIdsAndAnswerIds = memoize(questions => {
  // There was a bug where the "isCorrect" flag was nulled.
  // 
  // At first I thought this was because the user's "examCheatsEnabled" flag
  // being false nulls it... but that is only for exams that
  // are to be checked on the backend only.
  // 
  // For exams checked on the frontend, the "isCorrect" flag is always
  // set and this function is only called for these cases so some
  // of the ifs I added for satefy probably won't ever get used,
  // but keeping them anyways just to be safer:
  const correctChoiceIdOfFirstQuestion = get(questions, '[0].choices[0].id');
  if (!correctChoiceIdOfFirstQuestion) {
    return {};
  }

  return questions.reduce((acc, { id: questionId, choices = [] }) => {
    const correctChoiceId = get(choices.find(x => x.isCorrect), 'id');
    if (!correctChoiceId) return acc;

    return {
      ...acc,
      [questionId]: correctChoiceId,
    }
  }, {})
})

const _getUsersCorrectQuestionIdsAndAnswerIds = memoize(userQA => correctQA => {
  let retVal = {};
  for (let k of Object.keys(userQA)) {
    if (userQA[k] === correctQA[k]) {
      retVal[k] = userQA[k];
    }
  }

  return retVal;
})

export {
  getApiUrl,
  checkInDevMode,
  checkInProdMode,
  displayDate,
  displayMoney,
  getResponseError,
  useQuery,
  useMutation,
  renderComponentOrFunction,
  performChecking,
};
