import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import { noop } from 'lodash';

import NoPrint from 'components/common/NoPrint';
import RouteContext from 'components/Route/context';

const useStyles = makeStyles((theme) => ({
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const PrintFab = props => {
  const classes = useStyles();

  return (
    <Button
      variant='contained'
      aria-label='print'
      className={'ml-2 bg-blue-400 text-white'}
      {...props}
    >
      <PrintIcon className={classes.extendedIcon} />

      Print
    </Button>
  )
}

const ExamPrintReportButton = ({
  callContextCallbacks,
  onBeforeGetContent: onBeforeGetContentFromProps,
  onAfterPrint: onAfterPrintFromProps,
  ...rest
}) => { 
  const {
    ref,
    onBeforeGetContent: onBeforeGetContentFromContext,
    onAfterPrint: onAfterPrintFromContext,
  } = useContext(RouteContext);
  
  if (!ref) return null;
  
  return (
    <NoPrint>
      <ReactToPrint
        trigger={() => <PrintFab {...rest} />}
        content={() => ref}
        onBeforeGetContent={() => {
          onBeforeGetContentFromProps();
          if (callContextCallbacks) { onBeforeGetContentFromContext() };
        }}
        onAfterPrint={() => {
          onAfterPrintFromProps();
          if (callContextCallbacks) { onAfterPrintFromContext() };
        }}
        {...rest}
      />
    </NoPrint>
  )
}

ExamPrintReportButton.propTypes = {
  callContextCallbacks: PropTypes.bool,
}

ExamPrintReportButton.defaultProps = {
  callContextCallbacks: true,
  onBeforeGetContent: noop,
  onAfterPrint: noop,
}

export default memo(ExamPrintReportButton);
